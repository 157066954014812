import axios from "axios";
import BoxApi from "@/common/BoxApi";
import UserInfoMeModel from "@/common/model/UserInfoMeModel";

interface Params {
  [key: string]: any;
}

class BoxRequest {
  //send http post request
  post = async (url: string, params: Params) => {
    //在headers中添加token
    this.readTokenFromLocalStorage();

    try {
      const response = await axios.post(url, params);
      //如果response.data 为空或者response.data.success=false, 弹窗提示错误
      if (!response.data.success) {
        console.info(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(`Request to ${url} failed with error ${error}`);
      throw error;
    }
  }

  postWithFiles = async (url: string, params: Params, onUploadProgress: (progress: number) => void) => {
    this.readTokenFromLocalStorage();

    try {
      const response = await axios.post(url, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;
          onUploadProgress(percentCompleted);
        }
      });
      //如果response.data 为空或者response.data.success=false, 弹窗提示错误
      if (!response.data.success) {
        console.info(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(`Request to ${url} failed with error ${error}`);
      throw error;
    }
  }

  //send http get request
  get = async (url: string, params: Params) => {
    this.readTokenFromLocalStorage();

    try {
      const response = await axios.get(url, { params });
      //如果response.data 为空或者response.data.success=false, 弹窗提示错误
      if (!response.data.success) {
        console.info(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(`Request to ${url} failed with error ${error}`);
      throw error;
    }
  }

  //read token from localStorage
  readTokenFromLocalStorage = () => {
    const tokenName = localStorage.getItem('tokenName');
    const tokenValue = localStorage.getItem('tokenValue');

    if (tokenName && tokenValue) {
      axios.defaults.headers.common[tokenName] = tokenValue;
    }
  }


  //判断是否登录
  getLoginFlag = (): boolean => {
    const satoken = localStorage.getItem('tokenValue');
    const tokenTimeoutAt = localStorage.getItem('tokenTimeoutAt');
    if (!tokenTimeoutAt) {
      return false;
    }
    // token 失效
    if (Date.now() > Number(tokenTimeoutAt)) {
      return false;
    }

    if (satoken) {
      return true;
    } else {
      return false;
    }
  }

  // 调用接口查询用户信息
  queryUserInfo = async (): Promise<UserInfoMeModel | null> => {
    try {
      let resp = await this.post(BoxApi.userInfoMe, {});
      if (resp.success) {
        return resp.data ?? null;
      } else {
        console.warn(`get userInfoMe fail,errMsg: ${resp.debugMsg}`);
        return null;
      }
    } catch (err) {
      console.error('get userInfoMe fail,errMsg:', err);
      return null;
    }
  }

  // 获取用户信息
  getUserInfo = async (): Promise<UserInfoMeModel | null> => {
    if (!this.getLoginFlag()) {
      // 登录态失效, 返回空数据
      return null;
    }
    const userInfoStr = localStorage.getItem('userInfo');
    if (userInfoStr) {
      // 直接从 localstorage 存储的 json 解析
      return JSON.parse(userInfoStr);
    }

    let userInfo = await this.queryUserInfo();
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }
    return userInfo;
  };

  // 获取服务端公钥
  getServerPublicKey = async (): Promise<string | null> => {
    let publicKey: string | null = localStorage.getItem('serverPublicKey');
    if (publicKey) {
      return publicKey;
    }

    try {
      let resp = await this.get(BoxApi.getPublicKey, {});
      if (resp.success) {
        publicKey = resp.data ?? '';
      } else {
        console.warn(`get publicKey fail,errMsg: ${resp.debugMsg}`);
        publicKey = '';
      }
    } catch (err) {
      console.error('get publicKey fail,errMsg:', err);
      publicKey = '';
    }
    if(publicKey){
      localStorage.setItem('serverPublicKey', publicKey);
    }
    return publicKey;
  }

  // 从 localstorage 中删除用户信息
  removeLocalUserInfo = () => {
    localStorage.removeItem('userInfo');
  }


  logout = (): boolean => {
    // localStorage.removeItem('tokenName');
    // localStorage.removeItem('tokenValue');
    // localStorage.removeItem('tokenTimeoutAt');
    // localStorage.removeItem('userId');
    // localStorage.removeItem('userInfo');
    localStorage.clear();
    return true;
  };

}
const boxRequest = new BoxRequest();
export default boxRequest;
