import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface StatCardProps {
    icon: React.ReactNode;
    title: string;
    value: number;
    color: 'blue' | 'green' | 'yellow' | 'gray';
}

export function StatCard({ icon, title, value, color }: StatCardProps) {
    const colorClasses = {
        blue: 'bg-blue-50 text-blue-700 text-blue-800',
        green: 'bg-green-50 text-green-700 text-green-800',
        yellow: 'bg-yellow-50 text-yellow-700 text-yellow-800',
        gray: 'bg-gray-50 text-gray-600 text-gray-700',
    };

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className={`p-3 sm:p-4 rounded-lg flex items-center ${colorClasses[color]}`}>
                        {icon}
                        <div className="min-w-0 flex-1">
                            <h3 className="font-semibold text-sm sm:text-base truncate">{title}</h3>
                            <p className="text-xl sm:text-2xl">{value}</p>
                        </div>
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <p>{title}: {value}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}