import { useState, useEffect } from 'react'
import { useToast } from '@/components/ui/use-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import BoxApi from '@/common/BoxApi'
import BoxRequest from '@/common/util/BoxRequest'
import { BoxRespModel } from '@/common/model/BoxRespModel'
import { InboxInfoModel } from '@/common/model/InboxInfoModel'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { Textarea } from "@/components/ui/textarea"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Share2, Lock, Bell, FileText, FileUp, Copy, ArrowRight } from 'lucide-react'
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'

export default function ShareInbox() {
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [inboxInfo, setInboxInfo] = useState<InboxInfoModel | null>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [cutoffTime, setCutoffTime] = useState<string>('');
  const [hasPwd, setHasPwd] = useState<boolean>(false);
  const [notifyOnDeadline, setNotifyOnDeadline] = useState<boolean>(false);
  const [notifyEmail, setNotifyEmail] = useState<string>('');
  const [maxFileCount, setMaxFileCount] = useState<number>(0);
  const [maxFileSize, setMaxFileSize] = useState<number>(0);
  const [shareLink, setShareLink] = useState<string>('');
  const [copied, setCopied] = useState(false);

  const inboxId = new URLSearchParams(location.search).get('inboxId');

  useEffect(() => {
    const fetchInboxInfo = async () => {
      if (location.state?.inboxInfo) {
        setInboxInfo(location.state.inboxInfo);
      } else if (inboxId) {
        try {
          const resp: BoxRespModel<InboxInfoModel> = await BoxRequest.post(BoxApi.getInboxInfo, { inboxId: inboxId });
          if (resp.success && resp.data) {
            setInboxInfo(resp.data);
          } else {
            throw new Error(resp.displayMsg || 'Failed to get inbox info');
          }
        } catch (err) {
          toast({
            title: "Get inbox info fail.",
            description: `${err}`,
          });
          setTimeout(() => {
            navigate('/404');
          }, 2000);
        }
      } else {
        navigate('/404');
      }
    };

    fetchInboxInfo();
  }, [inboxId, location.state, navigate, toast]);

  useEffect(() => {
    if (inboxInfo) {
      setTitle(inboxInfo.name || '');
      setDescription(inboxInfo.description || '');
      
      let deadlineTime = new Date(inboxInfo.deadlineTime);
      const expireTimeStr = `${deadlineTime.getFullYear()}-${String(deadlineTime.getMonth() + 1).padStart(2, '0')}-${String(deadlineTime.getDate()).padStart(2, '0')}T${String(deadlineTime.getHours()).padStart(2, '0')}:${String(deadlineTime.getMinutes()).padStart(2, '0')}`;
      setCutoffTime(expireTimeStr);
      
      setHasPwd(inboxInfo.hasPwd || false);
      setNotifyOnDeadline(inboxInfo.notifyOnDeadline || false);
      setNotifyEmail(inboxInfo.notifyEmail || '');
      setMaxFileCount(inboxInfo.maxFileCount || 0);
      setMaxFileSize(Math.floor((inboxInfo.maxFileSize || 0) / (1024 * 1024))); // Convert bytes to MB
    }
  }, [inboxInfo]);

  useEffect(() => {
    if (inboxId) {
      setShareLink(`${window.location.origin}/inbox/${inboxId}/info`);
    }
  }, [inboxId]);

  const copyShareLink = () => {
    if (shareLink) {
      navigator.clipboard.writeText(shareLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      toast({
        title: "Link Copied",
        description: "The share link has been copied to your clipboard.",
      });
    }
  }

  const jumpToInboxPage = () => {
    if (inboxId) {
      navigate(`/inbox/${inboxId}/info`);
    }
  }

  if (!inboxInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <BoxPageHeader />
      <main className="flex-grow container mx-auto px-4 py-8">
        <Card className="max-w-3xl mx-auto">
          <CardHeader>
            <CardTitle className="text-2xl font-bold flex items-center gap-2">
              <Share2 className="w-6 h-6 text-blue-500" />
              Share Your Inbox
            </CardTitle>
            <CardDescription>Share this inbox information with others</CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="inboxTitle">Inbox Title</Label>
              <Input id="inboxTitle" value={title} readOnly className="bg-gray-100 cursor-not-allowed" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="deadline">Deadline</Label>
              <Input id="deadline" type="datetime-local" value={cutoffTime} readOnly className="bg-gray-100 cursor-not-allowed" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Textarea id="description" value={description} readOnly className="bg-gray-100 cursor-not-allowed" />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Lock className="w-4 h-4 text-gray-500" />
                <Label htmlFor="passwordProtection">Password Protection</Label>
              </div>
              <Switch id="passwordProtection" checked={hasPwd} disabled />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Bell className="w-4 h-4 text-gray-500" />
                <Label htmlFor="notifyOnDeadline">Notify on Deadline</Label>
              </div>
              <Switch id="notifyOnDeadline" checked={notifyOnDeadline} disabled />
            </div>
            {notifyOnDeadline && (
              <div className="space-y-2">
                <Label htmlFor="notificationEmail">Notification Email</Label>
                <Input id="notificationEmail" type="email" value={notifyEmail} readOnly className="bg-gray-100 cursor-not-allowed" />
              </div>
            )}
            <div className="flex justify-between">
              <div className="flex items-center space-x-2">
                <FileText className="w-4 h-4 text-gray-500" />
                <span className="text-sm text-gray-600">Max File Count: {maxFileCount}</span>
              </div>
              <div className="flex items-center space-x-2">
                <FileUp className="w-4 h-4 text-gray-500" />
                <span className="text-sm text-gray-600">Max File Size: {maxFileSize} MB</span>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="shareLink">Share Link</Label>
              <div className="flex space-x-2">
                <Input id="shareLink" value={shareLink} readOnly className="flex-grow bg-gray-100 cursor-not-allowed" />
                <Button onClick={copyShareLink} variant="outline">
                  {copied ? 'Copied!' : 'Copy'}
                  <Copy className="w-4 h-4 ml-2" />
                </Button>
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <Button className="w-full" onClick={jumpToInboxPage}>
              Go to Inbox Page
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </CardFooter>
        </Card>
      </main>
      <BoxPageFooter />
    </div>
  )
}
