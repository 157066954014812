import { useState } from "react";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { format, isBefore } from "date-fns";
import { toast } from '@/components/ui/use-toast';

interface DateTimePickerProps {
  onConfirm: (date: Date | undefined) => void;
}

export default function DateTimePicker({ onConfirm }: DateTimePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const handleDateSelect = (newDate: Date | undefined) => {
    if (newDate) {
      const now = new Date();
      newDate.setHours(now.getHours());
      newDate.setMinutes(now.getMinutes());
      setSelectedDate(newDate);
    }
  };

  const handleHourSelect = (value: string) => {
    if (selectedDate) {
      const newDate = new Date(selectedDate);
      newDate.setHours(parseInt(value));
      setSelectedDate(newDate);
    }
  };

  const handleMinuteSelect = (value: string) => {
    if (selectedDate) {
      const newDate = new Date(selectedDate);
      newDate.setMinutes(parseInt(value));
      setSelectedDate(newDate);
    }
  };

  const handleConfirm = () => {
    if (selectedDate && isBefore(selectedDate, new Date())) {
      toast({
        variant: 'destructive',
        title: "Invalid Date",
        description: "The selected date and time cannot be in the past.",
      });
      return;
    }
    onConfirm(selectedDate);
  };

  return (
    <div className="w-[280px] p-3">
      <div className="text-center mb-2 text-lg font-semibold">
        Set new deadline
      </div>
      <Calendar
        mode="single"
        selected={selectedDate}
        onSelect={handleDateSelect}
        initialFocus
        disabled={(date) => isBefore(date, new Date())}
      />
      <div className="flex items-center justify-center space-x-2 mt-2">
        <Select onValueChange={handleHourSelect} value={selectedDate ? format(selectedDate, "HH") : undefined}>
          <SelectTrigger className="w-[70px]">
            <SelectValue placeholder="Hour" />
          </SelectTrigger>
          <SelectContent>
            {Array.from({ length: 24 }, (_, i) => (
              <SelectItem key={i} value={i.toString().padStart(2, '0')}>
                {i.toString().padStart(2, '0')}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <span>:</span>
        <Select onValueChange={handleMinuteSelect} value={selectedDate ? format(selectedDate, "mm") : undefined}>
          <SelectTrigger className="w-[70px]">
            <SelectValue placeholder="Minute" />
          </SelectTrigger>
          <SelectContent>
            {Array.from({ length: 60 }, (_, i) => (
              <SelectItem key={i} value={i.toString().padStart(2, '0')}>
                {i.toString().padStart(2, '0')}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <Button onClick={handleConfirm} className="w-full mt-2">Confirm</Button>
    </div>
  );
} 