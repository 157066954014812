import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Switch } from "@/components/ui/switch"
import { useState, useEffect } from 'react'
import { CreateInboxModel } from '@/common/model/request/InboxReqModel'
import { useToast } from '@/components/ui/use-toast'
import BoxApi from '@/common/BoxApi'
import BoxRequest from '@/common/util/BoxRequest'
import { BoxRespModel } from '@/common/model/BoxRespModel'
import { InboxInfoModel } from '@/common/model/InboxInfoModel'
import { useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'

export default function Component() {
  const { toast } = useToast();
  const navigate = useNavigate();

  const today = new Date();
  const currentTimeStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}T${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;
  const sevenDayLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
  const sevenDayLaterStr = `${sevenDayLater.getFullYear()}-${String(sevenDayLater.getMonth() + 1).padStart(2, '0')}-${String(sevenDayLater.getDate()).padStart(2, '0')}T${String(sevenDayLater.getHours()).padStart(2, '0')}:${String(sevenDayLater.getMinutes()).padStart(2, '0')}`;

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [cutoffTime, setCutoffTime] = useState<string>(sevenDayLaterStr)
  const [hasPwd, setHasPwd] = useState<boolean>(false)
  const [pwd, setPwd] = useState<string>('')
  const [notifyOnDeadline, setNotifyOnDeadline] = useState<boolean>(false)
  const [notifyEmail, setNotifyEmail] = useState<string>('')
  const [maxFileCount, setMaxFileCount] = useState<number>(50)
  const [maxFileSize, setMaxFileSize] = useState<number>(10)
  const [limitFileTypeFlag, setLimitFileTypeFlag] = useState<boolean>(false)
  const [allowFileTypes, setAllowFileTypes] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')

  // 在组件加载时获取用户信息
  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await BoxRequest.getUserInfo();
      if (userInfo && userInfo.email) {
        setUserEmail(userInfo.email);
      }
    };
    fetchUserInfo();
  }, []);

  // 检参数
  const checkParam = (): boolean => {
    if (!title.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Title cannot be empty',
      })
      return false;
    }

    if (!description.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Description cannot be empty',
      })
      return false;
    }

    const cutoffDate = new Date(cutoffTime);
    if (cutoffDate.getTime() < Date.now()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Deadline time cannot be in the past',
      })
      return false;
    }

    if (hasPwd && !pwd.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Password cannot be empty when enabled',
      })
      return false;
    }

    if (notifyOnDeadline && !notifyEmail.trim()) {
      toast({
        variant: "destructive",
        title: "Create Failed",
        description: 'Notification email cannot be empty when enabled',
      })
      return false;
    }

    return true;
  }

  // 创建收件夹功能
  const createInbox = () => {
    if (!checkParam()) {
      return;
    }

    const createInboxModel: CreateInboxModel = {
      name: title,
      description: description,
      hasPwd: hasPwd,
      pwd: hasPwd ? pwd : undefined,
      pwdHash: hasPwd ? CryptoJS.SHA256(pwd).toString(CryptoJS.enc.Hex) : undefined,
      notifyOnDeadline: notifyOnDeadline,
      notifyEmail: notifyOnDeadline ? notifyEmail : null,
      deadlineTime: new Date(cutoffTime).getTime(),
      maxFileCount: maxFileCount,
      maxFileSize: maxFileSize * 1024 * 1024,
      limitFileTypeFlag: limitFileTypeFlag,
      allowFileTypes: limitFileTypeFlag ? allowFileTypes : undefined,
    }

    BoxRequest.post(BoxApi.createInbox, createInboxModel)
      .then((resp: BoxRespModel<InboxInfoModel>) => {
        if (resp.success) {
          toast({
            title: "Create Success",
            description: 'Inbox created successfully',
          })
          navigate(`/inbox/share?inboxId=${resp.data?.inboxId ?? ''}`, { state: { inboxInfo: resp.data } });
        } else {
          toast({
            variant: "destructive",
            title: "Create Failed",
            description: resp.displayMsg
          })
        }
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Create Failed",
          description: err.message,
        })
      })
  }

  // 修改 setNotifyOnDeadline 函数
  const handleNotifyOnDeadlineChange = (checked: boolean) => {
    setNotifyOnDeadline(checked);
    if (checked && !notifyEmail && userEmail) {
      setNotifyEmail(userEmail);
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <BoxPageHeader />
      <main className="flex-grow container mx-auto px-4 py-8">
        <section className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto" aria-label="Create File Form">
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">Create New Inbox</h1>
          <form onSubmit={(e) => { e.preventDefault(); createInbox(); }} className="space-y-6">
            <div>
              <Label htmlFor="inboxTitle" className="text-sm font-medium text-gray-700">Inbox Title</Label>
              <Input 
                id="inboxTitle" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                placeholder="Enter inbox title"
                className="mt-1"
                required
              />
            </div>
            <div>
              <Label htmlFor="inboxDescription" className="text-sm font-medium text-gray-700">Description</Label>
              <Textarea 
                id="inboxDescription"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe the purpose of this inbox"
                className="mt-1"
                required
              />
            </div>
            <div>
              <Label htmlFor="inboxDeadline" className="text-sm font-medium text-gray-700">Deadline</Label>
              <Input 
                id="inboxDeadline"
                type="datetime-local" 
                min={currentTimeStr}
                value={cutoffTime} 
                onChange={(e) => setCutoffTime(e.target.value)} 
                className="mt-1"
                required
              />
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="hasPwd"
                checked={hasPwd}
                onCheckedChange={setHasPwd}
              />
              <Label htmlFor="hasPwd" className="cursor-pointer text-sm font-medium text-gray-700">Enable Password Protection</Label>
            </div>
            {hasPwd && (
              <div>
                <Label htmlFor="pwd" className="text-sm font-medium text-gray-700">Password</Label>
                <Input 
                  id="pwd" 
                  type="password"
                  value={pwd} 
                  onChange={(e) => setPwd(e.target.value)} 
                  placeholder="Enter password"
                  className="mt-1"
                />
              </div>
            )}
            <div className="flex items-center space-x-2">
              <Switch
                id="notifyOnDeadline"
                checked={notifyOnDeadline}
                onCheckedChange={handleNotifyOnDeadlineChange}
              />
              <Label htmlFor="notifyOnDeadline" className="cursor-pointer text-sm font-medium text-gray-700">Notify on Deadline</Label>
            </div>
            {notifyOnDeadline && (
              <div>
                <Label htmlFor="notifyEmail" className="text-sm font-medium text-gray-700">Notification Email</Label>
                <Input 
                  id="notifyEmail" 
                  type="email"
                  value={notifyEmail} 
                  onChange={(e) => setNotifyEmail(e.target.value)} 
                  placeholder="Enter email for notifications"
                  className="mt-1"
                />
              </div>
            )}
            <div>
              <Label htmlFor="maxFileCount" className="text-sm font-medium text-gray-700">Max File Count</Label>
              <Input 
                id="maxFileCount" 
                type="number"
                value={maxFileCount} 
                onChange={(e) => setMaxFileCount(parseInt(e.target.value))} 
                min="1"
                className="mt-1"
              />
            </div>
            <div>
              <Label htmlFor="maxFileSize" className="text-sm font-medium text-gray-700">Max File Size (MB)</Label>
              <Input 
                id="maxFileSize" 
                type="number"
                value={maxFileSize} 
                onChange={(e) => setMaxFileSize(parseInt(e.target.value))} 
                min="1"
                className="mt-1"
              />
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="limitFileTypeFlag"
                checked={limitFileTypeFlag}
                onCheckedChange={setLimitFileTypeFlag}
              />
              <Label htmlFor="limitFileTypeFlag" className="text-sm font-medium text-gray-700">Limit File Types</Label>
            </div>
            {limitFileTypeFlag && (
              <div>
                <Label htmlFor="allowFileTypes" className="text-sm font-medium text-gray-700">Allowed File Types</Label>
                <Input 
                  id="allowFileTypes" 
                  value={allowFileTypes} 
                  onChange={(e) => setAllowFileTypes(e.target.value)} 
                  placeholder="e.g. .pdf,.doc,.jpg"
                  className="mt-1"
                />
              </div>
            )}
            <button 
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
            >
              Create Inbox
            </button>
          </form>
        </section>
      </main>
      <BoxPageFooter />
    </div>
  )
}
