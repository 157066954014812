import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'

export default function TermsOfService() {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <BoxPageHeader />
      <main className="flex-grow container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        <p className="text-sm text-gray-600 mb-6">Last updated: Nov 01, 2024</p>
        
        <section className="mb-8">
          <p className="mb-4">
            Welcome to File Box. Please read these Terms of Service ("Terms") carefully before using our application (the "Service") operated by File Box Inc. ("we," "us," or "our").
          </p>
          <p className="mb-4">
            By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Description of Service</h2>
          <p className="mb-4">
            File Box is a digital file management application that allows users to create a file inbox to collect, organize, and manage digital files. Our service includes features such as:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Secure file storage</li>
            <li>File sharing capabilities</li>
            <li>Advanced organization tools</li>
            <li>Cross-platform synchronization</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. User Accounts</h2>
          <p className="mb-4">
            2.1. To use our Service, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
          </p>
          <p className="mb-4">
            2.2. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
          </p>
          <p className="mb-4">
            2.3. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Content and Use of Service</h2>
          <p className="mb-4">
            3.1. Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you upload, post, transmit, or otherwise make available via the Service.
          </p>
          <p className="mb-4">
            3.2. By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service.
          </p>
          <p className="mb-4">
            3.3. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.
          </p>
        </section>

        {/* Add more sections as needed */}

      </main>
      <BoxPageFooter />
    </div>
  )
}
