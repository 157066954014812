import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BoxRequest from '@/common/util/BoxRequest';
import { Button } from "@/components/ui/button";
import BoxApi from '@/common/BoxApi';
import { toast } from '@/components/ui/use-toast';
import { BarChart, Inbox, Clock, Archive, HardDrive, Activity } from 'lucide-react';
import { StatCard } from '@/components/StatCard';
import { RefreshCw } from 'lucide-react';
import { Progress } from "@/components/ui/progress"
import { DashBoardStatisticsStorageModel, DashBoardStatisticsInboxModel, DashBoardRecentActivityModel } from '@/common/model/response/DashBoardModel';

/**
 * 格式化存储大小
 * @param bytes 字节数
 * @returns 格式化后的存储大小
 */
const formatStorageSize = (bytes: number) => {
    const GB = 1024 * 1024 * 1024;
    const MB = 1024 * 1024;
    const KB = 1024;
    
    if (bytes >= GB) {
        return `${parseFloat((bytes / GB).toFixed(2))} GB`;
    } else if (bytes >= MB) {
        return `${parseFloat((bytes / MB).toFixed(2))} MB`;
    } else {
        return `${parseFloat((bytes / KB).toFixed(2))} KB`;
    }
};
/**
 * 计算存储占用百分比, 返回 0~100 数字
 */
const calculateStoragePercentage = (current: number, limit: number): number => {
    if (!limit || limit <= 0) return 0;
    return Math.min(Math.round((current / limit) * 100), 100);
};

export default function Dashboard() {
    const navigate = useNavigate();
    const [storageStats, setStorageStats] = useState<DashBoardStatisticsStorageModel>(new DashBoardStatisticsStorageModel());
    const [inboxStats, setInboxStats] = useState<DashBoardStatisticsInboxModel>(new DashBoardStatisticsInboxModel());
    const [recentActivities, setRecentActivities] = useState<DashBoardRecentActivityModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!BoxRequest.getLoginFlag()) {
            navigate('/signin');
            return;
        }

        loadDashboardData();
    }, [navigate]);

    const loadDashboardData = async () => {
        setIsLoading(true);
        try {
            const [storageResp, inboxResp, activityResp] = await Promise.all([
                BoxRequest.get(BoxApi.statisticsStorage, {}),
                BoxRequest.get(BoxApi.statisticsInbox, {}),
                BoxRequest.get(BoxApi.recentActivity, {})
            ]);

            if (storageResp.success && storageResp.data) {
                setStorageStats(storageResp.data);
            }

            if (inboxResp.success && inboxResp.data) {
                setInboxStats(inboxResp.data);
            }

            if (activityResp.success && activityResp.data) {
                setRecentActivities(activityResp.data);
            }
        } catch (err) {
            console.warn(`Query dashboard data failed: ${err}`);
            toast({
                variant: 'destructive',
                title: "Query Failed",
                description: `${err}`,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const refreshData = () => {
        loadDashboardData();
    };

    return (
        <div className="flex flex-col h-full overflow-hidden">
            {isLoading ? (
                <div className="flex items-center justify-center h-full">
                    <p>Loading...</p>
                </div>
            ) : (
                <div className="flex flex-col h-full overflow-hidden">
                    <div className="p-4 sm:p-6 border-b border-gray-200">
                        <div className="flex justify-between items-center mb-6">
                            <h1 className="text-xl sm:text-2xl font-semibold text-gray-800">Dashboard Overview</h1>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={refreshData}
                                className="flex items-center text-blue-600 border-blue-600 hover:bg-blue-50"
                            >
                                <RefreshCw className="h-4 w-4 mr-2" />
                                Refresh
                            </Button>
                        </div>
                        
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                            <StatCard 
                                icon={<BarChart className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mr-2 sm:mr-3 flex-shrink-0" />}
                                title="Total Collections"
                                value={inboxStats.totalSize}
                                color="blue" 
                            />
                            <StatCard 
                                icon={<Inbox className="h-6 w-6 sm:h-8 sm:w-8 text-green-500 mr-2 sm:mr-3 flex-shrink-0" />}
                                title="Active Collections"
                                value={inboxStats.activeSize}
                                color="green" 
                            />
                            <StatCard 
                                icon={<Clock className="h-6 w-6 sm:h-8 sm:w-8 text-yellow-500 mr-2 sm:mr-3 flex-shrink-0" />}
                                title="Closing Soon"
                                value={inboxStats.closingSoonSize}
                                color="yellow" 
                            />
                            <StatCard 
                                icon={<Archive className="h-6 w-6 sm:h-8 sm:w-8 text-gray-400 mr-2 sm:mr-3 flex-shrink-0" />}
                                title="Expired Collections"
                                value={inboxStats.expiredSize}
                                color="gray" 
                            />
                        </div>
                    </div>
                    
                    <div className="flex-1 overflow-auto p-4 sm:p-6">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            {/* Storage Usage */}
                            <div className="bg-white p-6 rounded-lg shadow">
                                <h2 className="text-lg font-semibold mb-4 flex items-center">
                                    <HardDrive className="h-5 w-5 mr-2 text-blue-500" />
                                    Storage Usage
                                </h2>
                                <Progress 
                                    value={calculateStoragePercentage(
                                        storageStats.currentStorageBytes,
                                        storageStats.limitStorageBytes
                                    )}
                                    className="mb-2" 
                                />
                                <p className="text-sm text-gray-600">
                                    {formatStorageSize(storageStats.currentStorageBytes)} / {formatStorageSize(storageStats.limitStorageBytes)}
                                </p>
                            </div>

                            {/* Recent Activity */}
                            <div className="bg-white p-6 rounded-lg shadow">
                                <h2 className="text-lg font-semibold mb-4 flex items-center">
                                    <Activity className="h-5 w-5 mr-2 text-green-500" />
                                    Recent Activity
                                </h2>
                                <ul className="space-y-3">
                                    {recentActivities.map((activity, index) => (
                                        <li key={index} className="flex items-center text-sm">
                                            <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                            <span className="flex-grow">{activity.description}</span>
                                            <span className="text-gray-400">
                                                {new Date(activity.timestamp).toLocaleDateString()}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
