import React, { useState } from 'react';
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationPrevious, PaginationNext, PaginationEllipsis } from '@/components/ui/pagination';
import { SelectValue, SelectTrigger, SelectItem, SelectContent, Select, SelectLabel, SelectGroup } from "@/components/ui/select"
import { Input } from '@/components/ui/input';

interface Props {
    currentPage: number;
    totalPages: number;
    totalCount?: number;
    initPageSize?: number;
    onPageChange: (page: number, pageSize: number) => void;
}

const CustomPagination: React.FC<Props> = ({ currentPage, totalPages, totalCount, initPageSize = 10, onPageChange }) => {


    const [pageSize, setPageSize] = useState<number>(initPageSize);
    const [jumpPageValue, setJumpPageValue] = useState<string>('');

    const handlePageSizeChange = (newPageSize: string) => {
        let newPageSizeNumber: number = parseInt(newPageSize);
        if (pageSize === newPageSizeNumber)
            return;
        setPageSize(newPageSizeNumber);
        onPageChange(1, newPageSizeNumber);
    };

    const handleJumpPageValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!isNaN(parseInt(value))) {
            setJumpPageValue(value);
        }
    }

    /**
     * 跳转页面输入框按键事件
     */
    const handleJumpPageInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // 判断是否可以跳转
            let value = parseInt(jumpPageValue)
            if (value === currentPage || value < 1 || value > totalPages) {
                setJumpPageValue(String(currentPage));
                return;
            }
            onPageChange(value, pageSize);
        }
    };

    return (
        <Pagination className="justify-end">

            <PaginationContent>
                <span className={`${!totalCount ? 'hidden' : ''}`}>total: {totalCount}</span>

                {/* 上一页 */}
                <PaginationItem>
                    <PaginationPrevious href="#" className={`${currentPage <= 1 ? 'cursor-not-allowed text-gray-500' : ''}`}
                        onClick={() => {
                            if (currentPage <= 1 || totalPages === 0)
                                return;
                            onPageChange(currentPage - 1, pageSize)
                        }} />
                </PaginationItem>

                {/* 当总页数为1时，直接显示页码1 */}
                {totalPages === 1 && (
                    <PaginationItem key={`page-1`}>
                        <PaginationLink href="#" isActive={currentPage === 1} onClick={() => {
                            if (currentPage === 1)
                                return;
                            onPageChange(1, pageSize);
                        }}>
                            1
                        </PaginationLink>
                    </PaginationItem>
                )}

                {/* 显示前几页 */}
                {totalPages > 1 && Array.from({ length: currentPage }, (_, i) => i + 1).slice(-5).map(page => (
                    <PaginationItem key={`page-${page}`}>
                        <PaginationLink href="#" isActive={currentPage === page} onClick={() => {
                            if (currentPage === page)
                                return;
                            onPageChange(page, pageSize);
                        }}>
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                {currentPage > 5 && <PaginationEllipsis />}

                {/* 显示后几页 */}
                {Array.from({ length: totalPages - currentPage }, (_, i) => currentPage + i + 1).slice(0, 5).map(page => (
                    <PaginationItem key={`page-${page}`}>
                        <PaginationLink href="#" isActive={currentPage === page} onClick={() => {
                            if (currentPage === page)
                                return;
                            onPageChange(page, pageSize);
                        }}>
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                {/* 下一页 */}
                <PaginationItem>
                    <PaginationNext href="#" className={`${currentPage >= totalPages ? 'cursor-not-allowed text-gray-500' : ''}`}
                        onClick={() => {
                            if (currentPage >= totalPages)
                                return;
                            onPageChange(currentPage + 1, pageSize);
                        }} />
                </PaginationItem>


                <Select onValueChange={(newValue: string) => { handlePageSizeChange(newValue) }} defaultValue={`${pageSize}`}>
                    <SelectTrigger className='w-24'>
                        <SelectValue placeholder="page size" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>page size</SelectLabel>
                            <SelectItem value="5">5/page</SelectItem>
                            <SelectItem value="10">10/page</SelectItem>
                            <SelectItem value="25">25/page</SelectItem>
                            <SelectItem value="50">50/page</SelectItem>
                            <SelectItem value="100">100/page</SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>

                <div className="flex items-center space-x-2 ml-2">
                    <span>jump-to</span>
                    <Input className="w-16" type="number" value={jumpPageValue} onChange={handleJumpPageValueChange} onKeyDown={handleJumpPageInputKeyDown} />
                </div>
            </PaginationContent>
        </Pagination>
    );
};

export default CustomPagination;