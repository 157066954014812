import { lazy, Suspense } from 'react';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Button } from '@/components/ui/button'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useRef, useCallback } from 'react'
import { FiUploadCloud, FiZap, FiLock } from 'react-icons/fi'
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from '@/components/ErrorBoundary'; // 更新导入路径到正确的路径

const FaqPage = lazy(() => import('@/pages/home/intro/FaqPage'));
const PricingPage = lazy(() => import('@/pages/home/intro/PricingPage'));
const ContactUsPage = lazy(() => import('@/pages/home/intro/ContactUsPage'));

export default function Component() {

  const location = useLocation();

  const faqsRef = useRef<HTMLDivElement>(null)
  const pricingRef = useRef<HTMLDivElement>(null)
  const contactUsRef = useRef<HTMLDivElement>(null)

  const scrollToSection = useCallback(() => {
    if (location.hash === '#faqs' && faqsRef.current) {
      faqsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (location.hash === '#pricing' && pricingRef.current) {
      pricingRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (location.hash === '#contact-us' && contactUsRef.current) {
      contactUsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location.hash]);

  useEffect(() => {
    scrollToSection();
  }, [scrollToSection]);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const buttonVariants = {
    hover: { scale: 1.05, transition: { duration: 0.2 } }
  };

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <Helmet>
        <title>FileInbox - Simplify File Collection & Organization</title>
        <meta name="description" content="Streamline your workflow with fileinbox.online. Easy file collection, quick progress updates, and secure encryption." />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SoftwareApplication",
              "name": "FileInbox",
              "applicationCategory": "BusinessApplication",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
              },
              "description": "Simplify file collection and organization with FileInbox."
            }
          `}
        </script>
      </Helmet>

      <main>
        <div className='bg-cover bg-center' style={{ backgroundImage: "url('/image/landing_topbg.jpg')" }}>
          <BoxPageHeader defaultBgClassName='bg-transparent' scrollBgClassName='bg-white' />

          <motion.section 
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.5 }}
            className="w-full py-12 md:py-24 lg:py-32 xl:py-48 text-black text-center"
          >
            <div className="container px-4 md:px-6">
              <div className="flex flex-col items-center space-y-4">
                <h1 className="select-none text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl bg-gradient-to-r from-[#6366F1] to-[#EC4899] bg-clip-text text-transparent custom-filter-shadow">
                  Simplify File Collection & Organization
                </h1>
                <p className="max-w-lg mx-auto text-lg">Streamline your workflow with fileinbox.online</p>
                <Link to='/signin'>
                  <motion.div whileHover="hover" variants={buttonVariants}>
                    <Button className="bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-blue-600 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1" aria-label="Start Your Free Trial">
                      Start Your Free Trial
                    </Button>
                  </motion.div>
                </Link>
              </div>
            </div>
          </motion.section>

          <motion.section 
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="py-20 px-4 md:px-12 container bg-cover bg-center" 
            style={{backgroundImage: "url('/path/to/background-image.jpg')"}}
          >
            <div className="bg-white bg-opacity-90 rounded-lg p-4 md:p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col items-start justify-center">
                  <h2 className="text-2xl md:text-3xl font-bold mb-6 text-gray-800">Revolutionize Your File Collection</h2>
                  <div className="space-y-4 mb-6">
                    <div className="flex items-center">
                      <FiUploadCloud className="w-6 h-6 mr-2 text-purple-500" aria-hidden="true" />
                      <p className="text-base md:text-lg text-gray-700">Effortless file uploads and organization</p>
                    </div>
                    <div className="flex items-center">
                      <FiZap className="w-6 h-6 mr-2 text-purple-500" aria-hidden="true" />
                      <p className="text-base md:text-lg text-gray-700">Lightning-fast processing and notifications</p>
                    </div>
                    <div className="flex items-center">
                      <FiLock className="w-6 h-6 mr-2 text-purple-500" aria-hidden="true" />
                      <p className="text-base md:text-lg text-gray-700">Secure, encrypted file storage</p>
                    </div>
                  </div>
                  <motion.div whileHover="hover" variants={buttonVariants}>
                    <Button className="bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-blue-600 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1" size={"lg"} aria-label="Start your free trial">
                      Start your free trial
                    </Button>
                  </motion.div>
                </div>
                <div className="flex items-center justify-center">
                  <img
                    alt="Product information visualization"
                    className="rounded-lg shadow-md w-full h-auto"
                    src="/image/sample/sample_inbox.jpeg"
                    style={{
                      aspectRatio: "500/300",
                      // objectFit: "cover",
                    }}
                    width="500"
                    height="300"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </motion.section>

          <motion.section 
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="py-20 px-4 md:px-12 container"
          >
            <div className="flex flex-col items-center text-center space-y-8">
              <h2 className="text-2xl md:text-3xl font-bold mb-6">Core Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
                  <div className="relative w-full h-[200px] mb-4">
                    <img
                      alt="Easy File Collection"
                      className="absolute inset-0 w-full h-full object-contain rounded"
                      src="/image/sample/sample_dashboard.png"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Easy File Collection</h3>
                  <p className="text-lg mb-4">Create a collection folder, share the link, and files are automatically renamed and organized.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
                  <div className="relative w-full h-[200px] mb-4">
                    <img
                      alt="Quick Progress Updates"
                      className="absolute inset-0 w-full h-full object-contain rounded"
                      src="/image/sample/sample_email.png"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Quick Progress Updates</h3>
                  <p className="text-lg mb-4">Receive email notifications when your file collection task is completed.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
                  <div className="relative w-full h-[200px] mb-4">
                    <img
                      alt="Secure Encryption"
                      className="absolute inset-0 w-full h-full object-contain rounded"
                      src="/image/sample/sample_upload.png"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-4">Secure Encryption</h3>
                  <p className="text-lg mb-4">Folders can be password-protected and files are stored with encryption for added security.</p>
                </div>
              </div>
            </div>
          </motion.section>

          <motion.section 
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="py-20 px-4 md:px-12 bg-gray-100 container"
          >
            <div className="text-center">
              <h2 className="text-2xl md:text-3xl font-bold mb-6">Testimonials</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <blockquote className="text-base md:text-lg mb-4">
                    "This tool has been a game changer for our team. It's easy to use and has all the features we need to manage our projects effectively."
                  </blockquote>
                  <p>
                    <strong>- Jane Doe, Project Manager</strong>
                  </p>
                </div>
                <div>
                  <blockquote className="text-base md:text-lg mb-4">
                    "I love how I can manage all my finances in one place. It's saved me so much time and made my job a lot easier."
                  </blockquote>
                  <p>
                    <strong>- John Smith, Finance Manager</strong>
                  </p>
                </div>
              </div>
            </div>
          </motion.section>

          <Suspense fallback={<div>Loading...</div>}>
            <ErrorBoundary fallback={<div>Error loading content. Please try again later.</div>}>
              <motion.div 
                ref={faqsRef}
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <FaqPage />
              </motion.div>

              <motion.div 
                ref={pricingRef}
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ duration: 0.5, delay: 1 }}
              >
                <PricingPage />
              </motion.div>

              <motion.div 
                ref={contactUsRef}
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                transition={{ duration: 0.5, delay: 1.2 }}
              >
                <ContactUsPage />
              </motion.div>
            </ErrorBoundary>
          </Suspense>

        </div>
      </main>
      <BoxPageFooter />
    </div>
  )
}