import { debounce } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter'
import { Button } from "@/components/ui/button"
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useEffect, useRef, useState, useCallback } from 'react';
import CryptoJS from 'crypto-js'
import BoxRequest from '@/common/util/BoxRequest';
import BoxApi from '@/common/BoxApi';
import { TokenModel } from '@/common/model/TokenModel';
import { useToast } from "@/components/ui/use-toast"
import { BoxRespModel } from '@/common/model/BoxRespModel';
import JSEncrypt from 'jsencrypt';
import { FiUploadCloud, FiZap, FiLock } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GoogleLoginButton = ({ onSuccess }: { onSuccess: (response: any) => void }) => {
  const { toast } = useToast();
  
  return (
    <GoogleOAuthProvider clientId={BoxApi.googleApiKey}>
      <div className="w-full justify-center">
        <GoogleLogin
          onSuccess={onSuccess}
          onError={() => {
            toast({
              variant: "destructive",
              title: "Google Login Failed",
              description: "Authentication failed",
            });
          }}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default function Component() {
  const navigate = useNavigate();
  const { toast } = useToast()
  const rsaEncryptorRef = useRef(new JSEncrypt());

  // 获取默认type=login / signup
  const queryParams = new URLSearchParams(useLocation().search);
  const signinType: string = queryParams.get('type') || 'login';

  const [tabActive, setTabActive] = useState<string>(signinType); // ['login', 'signup']
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailCheckResult, setEmailCheckResult] = useState(' ');
  const [passwordCheckResult, setPasswordCheckResult] = useState(' ');
  const [confirmPasswordCheckResult, setConfirmPasswordCheckResult] = useState(' ');
  const [canLogin, setCanLogin] = useState(false);
  const [canSignUp, setCanSignUp] = useState(false);

  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    // 初始化读取rsa公钥
    BoxRequest.getServerPublicKey().then((pubKey) => {
      if (pubKey) {
        rsaEncryptorRef.current.setPublicKey(pubKey);
      }
    });
  }, []);

  useEffect(() => {
    //检查是否已经登录
    if (BoxRequest.getLoginFlag()) {
      setIsRedirecting(true);
      setTimeout(() => {
        console.log('already login, redirect to home page');
        navigate('/inboxes/dashboard');
      }, 2000);
    }
  }, [navigate]);




  // 检查邮箱函数
  const checkEmail = useCallback((emailStr: string) => {
    if (emailStr === '') {
      setEmailCheckResult('⚠️ email is required');
      return false;
    }
    //regex check email
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(emailStr)) {
      setEmailCheckResult('❌ email format is incorrect');
      return false;
    }
    setEmailCheckResult('');
    return true;
  }, []);

  // 检查密码格式
  const checkPassword = useCallback((passwordStr: string) => {
    if (passwordStr.length < 8) {
      setPasswordCheckResult('❌ password must be at least 8 characters');
      return false;
    }
    if (passwordStr.length > 20) {
      setPasswordCheckResult('❌ password must be less than 20 characters');
      return false;
    }
    setPasswordCheckResult('');
    return true;
  }, []);

  // 使用防抖优化输入检查
  const debouncedCheckEmail = useRef(debounce(checkEmail, 300)).current;
  const debouncedCheckPassword = useRef(debounce(checkPassword, 300)).current;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    debouncedCheckEmail(event.target.value);
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    debouncedCheckPassword(event.target.value);
  }

  useEffect(() => {
    setCanLogin(emailCheckResult === '' && passwordCheckResult === '');
  }, [email, password, emailCheckResult, passwordCheckResult]);

  // 检查确认密码
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    checkConfirmPassword(event.target.value);
  }
  const checkConfirmPassword = (confirmPasswordStr: string) => {
    if (confirmPasswordStr !== password) {
      setConfirmPasswordCheckResult('❌ password does not match');
      return false;
    }
    setConfirmPasswordCheckResult('');
    return true;
  }

  useEffect(() => {
    setCanSignUp(emailCheckResult === '' && passwordCheckResult === '' && confirmPasswordCheckResult === '');
  }, [email, password, confirmPassword, emailCheckResult, passwordCheckResult, confirmPasswordCheckResult]);

  // 登录逻辑
  const login = () => {
    if (!canLogin) {
      toast({
        title: "Login Fail",
        description: 'params check fail.',
      })
      return;
    }
    let pwdHash: string = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    let encryptedPwdHash: string = rsaEncryptorRef.current.encrypt(pwdHash).toString()

    console.info('pubKey:', rsaEncryptorRef.current.getPublicKeyB64());
    BoxRequest.post(BoxApi.login, {
      email: email,
      pwdHash: encryptedPwdHash
    }).then((resp: BoxRespModel<TokenModel>) => {
      if (!resp) {
        toast({
          title: "Login Fail",
          description: 'seems network error',
        })
        return;
      }

      if (resp.success && resp.data?.isLogin) {
        let tokenDto: TokenModel = resp.data;
        //save login token to localStorage
        localStorage.setItem('tokenName', tokenDto.tokenName);
        localStorage.setItem('tokenValue', tokenDto.tokenValue);
        localStorage.setItem('userId', tokenDto.loginId);
        let timeoutAt: number = Date.now() + tokenDto.tokenTimeout * 1000;
        localStorage.setItem('tokenTimeoutAt', String(timeoutAt));
        toast({
          title: "Login Success",
          description: '',
        })
        navigate('/inboxes');
      } else {
        toast({
          title: "Login Fail",
          description: resp.displayMsg || 'unknown error',
        })
      }
    }).catch((err) => {
      toast({
        variant: "destructive",
        title: "Login Fail",
        description: err.message,
      })
    })
  }

  // 注册逻辑
  const signup = () => {
    if (!canSignUp) {
      toast({
        title: "Sign Up Fail",
        description: 'params check fail.',
      })
      return;
    }
    let pwdHash: string = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    let encryptedPwdHash: string = rsaEncryptorRef.current.encrypt(pwdHash) as string;
    let encryptedPwdOrigin: string = rsaEncryptorRef.current.encrypt(password) as string;

    BoxRequest.post(BoxApi.register, {
      email: email,
      pwdOrigin: encryptedPwdOrigin,
      pwdHash: encryptedPwdHash
    }).then((resp) => {
      if (!resp) {
        toast({
          title: "Sign Up Fail",
          description: 'seems network error',
        })
        return;
      }
      let tokenDto: TokenModel = resp.data;
      if (resp.success && tokenDto.isLogin) {
        //save login token to localStorage
        localStorage.setItem('tokenName', tokenDto.tokenName);
        localStorage.setItem('tokenValue', tokenDto.tokenValue);
        localStorage.setItem('userId', tokenDto.loginId);
        let timeoutAt: number = Date.now() + tokenDto.tokenTimeout * 1000;
        localStorage.setItem('tokenTimeoutAt', String(timeoutAt));
        toast({
          title: "Sign Up Success",
          description: '',
        })
        navigate('/inboxes/list');
      } else {
        toast({
          title: "Sign Up Fail",
          description: resp.displayMsg || 'unknown error',
        })
      }
    }).catch((err) => {
      toast({
        variant: "destructive",
        title: "Sign Up Fail",
        description: err.message,
      })
    })
  }

  const handleGoogleSuccess = async (credentialResponse: any) => {

    BoxRequest.post(BoxApi.googleLogin, {
      credential: credentialResponse.credential
    }).then((resp) => {
      if (resp.success && resp.data?.isLogin) {
        const tokenDto: TokenModel = resp.data;
        //保存登录token
        localStorage.setItem('tokenName', tokenDto.tokenName);
        localStorage.setItem('tokenValue', tokenDto.tokenValue);
        localStorage.setItem('userId', tokenDto.loginId);
        let timeoutAt: number = Date.now() + tokenDto.tokenTimeout * 1000;
        localStorage.setItem('tokenTimeoutAt', String(timeoutAt));

        toast({
          title: "Google Login Success",
          description: '',
        });
        navigate('/inboxes');
      }
    }).catch((err) => {
      toast({
        variant: "destructive",
        title: "Google Login Failed",
        description: err.message,
      });
    })
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  if (isRedirecting) {
    return (
      <>
        <BoxPageHeader />
        <main className="flex flex-col items-center justify-center h-[calc(100vh-120px)] bg-gray-200">
          <Card className="w-[400px] text-center">
            <CardHeader>
              <CardTitle className="text-2xl font-bold">File Inbox</CardTitle>
              <CardDescription>Welcome back!</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex justify-center">
                  <svg className="animate-spin h-10 w-10 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                <p className="text-lg font-semibold text-gray-700">Already logged in</p>
                <p className="text-sm text-gray-500">Redirecting to your inbox...</p>
              </div>
            </CardContent>
          </Card>
        </main>
        <BoxPageFooter />
      </>
    );
  }

  return (
    <>
      <BoxPageHeader />

      <main className="flex flex-col md:flex-row items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 p-4">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md md:w-1/2 md:pr-8 mb-8 md:mb-0"
        >
          <h2 className="text-3xl font-bold mb-6 text-gray-800">Simplify File Collection & Organization</h2>
          <p className="text-xl mb-6 text-gray-600">Streamline your workflow with fileinbox.online</p>
          <ul className="space-y-4 mb-6">
            <li className="flex items-center">
              <FiUploadCloud className="w-6 h-6 mr-2 text-purple-500" />
              <span>Effortless file uploads and organization</span>
            </li>
            <li className="flex items-center">
              <FiZap className="w-6 h-6 mr-2 text-purple-500" />
              <span>Lightning-fast processing and notifications</span>
            </li>
            <li className="flex items-center">
              <FiLock className="w-6 h-6 mr-2 text-purple-500" />
              <span>Secure, encrypted file storage</span>
            </li>
          </ul>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full max-w-md md:w-1/2"
        >
          <Tabs defaultValue='login' className="w-[400px]" value={tabActive} onValueChange={(newVal) => { setTabActive(newVal) }}>
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="login">Log In</TabsTrigger>
              <TabsTrigger value="signup">Sign Up</TabsTrigger>
            </TabsList>
            <TabsContent value="login">
              <Card className="mx-auto">
                <CardHeader className="space-y-1">
                  <div className="flex items-center justify-center">
                    <CardTitle className="text-2xl font-bold ml-2">File Inbox</CardTitle>
                  </div>
                  <CardDescription>Log in to your account</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        className="w-full"
                        id="email"
                        placeholder="m@example.com"
                        required
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        aria-invalid={emailCheckResult !== ''}
                        aria-describedby="email-error"
                      />
                    </div>
                    <div className="space-y-2">
                      <p id="email-error" className="text-gray-400">{emailCheckResult}</p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Label htmlFor="password">Password</Label>
                        <Link className="ml-auto inline-block text-sm underline" to="#">
                          Forgot your password?
                        </Link>
                      </div>
                      <Input
                        className="w-full"
                        id="password"
                        required
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyDown={(event) => {
                          if (canLogin && event.key === 'Enter') {
                            login();
                          }
                        }}
                        aria-invalid={passwordCheckResult !== ''}
                        aria-describedby="password-error"
                      />
                    </div>
                    <div className="space-y-2">
                      <p id="password-error" className="text-gray-400">{passwordCheckResult}</p>
                    </div>
                    <Button className="w-full" disabled={!canLogin} type="button" onClick={login}>
                      Log In
                    </Button>
                    <GoogleLoginButton onSuccess={handleGoogleSuccess} />
                    <div className="mt-4 text-center text-sm">
                      Don't have an account?
                      <Link className="text-sm text-blue-500 hover:text-blue-400 ml-2 underline" to="#" onClick={(event) => {
                        event.preventDefault();
                        setTabActive("signup");
                      }}>
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="signup">
              <Card className="mx-auto">
                <CardHeader className="space-y-1">
                  <div className="flex items-center justify-center">
                    <CardTitle className="text-2xl font-bold ml-2">File Inbox</CardTitle>
                  </div>
                  <CardDescription>Sign up for a new account</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="new_email">Email</Label>
                      <Input className="w-full" id="new_email" placeholder="m@example.com" required type="email" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{emailCheckResult}</p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Label htmlFor="new_password">Password</Label>
                      </div>
                      <Input className="w-full" id="new_password" required type="password" value={password} onChange={handlePasswordChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{passwordCheckResult}</p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Label htmlFor="confirm_password">Confirm Password</Label>
                      </div>
                      <Input className="w-full" id="confirm_password" required type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                    </div>
                    <div className="space-y-2">
                      <p className="text-gray-400">{confirmPasswordCheckResult}</p>
                    </div>
                    <div className="text-xs text-gray-500 text-center mt-2">
                      By continuing, you agree to our{' '}
                      <Link to="/terms-of-service" className="underline text-blue-500 hover:text-blue-400">
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link to="/privacy-policy" className="underline text-blue-500 hover:text-blue-400">
                        Privacy Policy
                      </Link>
                    </div>
                    <Button className="w-full" disabled={!canSignUp} type="button" onClick={signup}>
                      Sign Up
                    </Button>
                    <GoogleLoginButton onSuccess={handleGoogleSuccess} />
                    <div className="mt-4 text-center text-sm">
                      Already have an account?
                      <Link className="text-sm text-blue-500 hover:text-blue-400 ml-2 underline" to="#" onClick={(event) => {
                        event.preventDefault();
                        setTabActive('login');
                      }}>
                        Log In
                      </Link>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </motion.div>
      </main>
      <BoxPageFooter />
    </>
  )
}
