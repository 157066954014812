import BoxApi from "@/common/BoxApi";
import { BoxRespModel } from "@/common/model/BoxRespModel";
import BoxRequest from "@/common/util/BoxRequest";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useRef, useState, useCallback } from "react"
import JSEncrypt from "jsencrypt";

export default function AccountForm() {
    const [oldPwd, setOldPwd] = useState<string>('');
    const [newPwd, setNewPwd] = useState<string>('');
    const [newPwdConfirm, setNewPwdConfirm] = useState<string>('');
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [newPwdError, setNewPwdError] = useState<string>('');
    const [confirmPwdError, setConfirmPwdError] = useState<string>('');
    const { toast } = useToast();
    const rsaEncryptorRef = useRef(new JSEncrypt());

    useEffect(() => {
        BoxRequest.getServerPublicKey().then((publicKey: string | null) => {
            if (publicKey) {
                rsaEncryptorRef.current.setPublicKey(publicKey);
            }
        })
    }, [])

    const handleSubmit = () => {
        // 提交密码修改请求
        if (!oldPwd || !newPwd || !newPwdConfirm) {
            toast({
                title: 'Error',
                description: 'Please fill in all fields.',
            });
            return;
        }

        if (newPwd !== newPwdConfirm) {
            toast({
                title: 'Error',
                description: 'Passwords do not match.',
            });
            return;
        }
        let encryptedOldPwd: string = rsaEncryptorRef.current.encrypt(oldPwd) as string;
        let encryptedNewPwd: string = rsaEncryptorRef.current.encrypt(newPwd) as string;
        BoxRequest.post(BoxApi.changePassword, {
            oldPwd: encryptedOldPwd,
            newPwd: encryptedNewPwd,
        }).then((resp: BoxRespModel<null> | null) => {
            if (resp && resp.success) {
                toast({
                    title: 'Success',
                    description: 'Password updated successfully.',
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toast({
                    title: 'Failed to update password',
                    description: resp?.displayMsg,
                });
            }
        }).catch((err) => {
            toast({
                title: 'Error',
                description: 'An error occurred while updating password:' + err.message,
            });
        })
    }

    const checkNewPassword = useCallback((password: string) => {
        if (password.length < 8) {
            setNewPwdError('Password must be at least 8 characters long.');
            return false;
        }
        if (!/(?=.*[A-Za-z])(?=.*\d)/.test(password)) {
            setNewPwdError('Password must include both letters and numbers.');
            return false;
        }
        setNewPwdError('');
        return true;
    }, []);

    const checkConfirmPassword = useCallback((password: string, confirmPassword: string) => {
        if (password !== confirmPassword) {
            setConfirmPwdError('Passwords do not match.');
            return false;
        }
        setConfirmPwdError('');
        return true;
    }, []);

    const checkCanSubmit = useCallback(() => {
        const isNewPwdValid = checkNewPassword(newPwd);
        const isConfirmPwdValid = checkConfirmPassword(newPwd, newPwdConfirm);
        setCanSubmit(oldPwd !== '' && isNewPwdValid && isConfirmPwdValid);
    }, [oldPwd, newPwd, newPwdConfirm, checkNewPassword, checkConfirmPassword]);

    useEffect(() => {
        checkCanSubmit();
    }, [checkCanSubmit]); 

    return (
        <div className="space-y-8 max-w-md mx-auto">
            <div className="space-y-6">
                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Old password</label>
                    <Input 
                        className="w-full focus-visible:ring-gray-400 focus-visible:ring-1"
                        type="password"
                        placeholder="Enter your current password"
                        value={oldPwd}
                        onChange={(e) => { setOldPwd(e.target.value) }} 
                    />
                </div>

                <div className="border-t border-gray-200 my-6"></div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">New password</label>
                    <Input 
                        className="w-full focus-visible:ring-gray-400 focus-visible:ring-1"
                        type="password"
                        placeholder="Enter your new password"
                        value={newPwd}
                        onChange={(e) => { 
                            setNewPwd(e.target.value);
                            checkNewPassword(e.target.value);
                        }} 
                    />
                    {newPwdError && <p className="text-xs text-red-500">{newPwdError}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Confirm new password</label>
                    <Input 
                        className="w-full focus-visible:ring-gray-400 focus-visible:ring-1"
                        type="password"
                        placeholder="Confirm your new password"
                        value={newPwdConfirm}
                        onChange={(e) => { 
                            setNewPwdConfirm(e.target.value);
                            checkConfirmPassword(newPwd, e.target.value);
                        }} 
                    />
                    {confirmPwdError && <p className="text-xs text-red-500">{confirmPwdError}</p>}
                </div>
            </div>
            
            <Button 
                className="w-full py-2 px-4 text-lg"
                disabled={!canSubmit} 
                onClick={() => { handleSubmit() }}
            >
                Update Password
            </Button>
        </div>
    )
}
