import { useEffect, useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate, Link } from 'react-router-dom';
import BoxRequest from '@/common/util/BoxRequest';
import { Button } from "@/components/ui/button";
import CustomPagination from '@/components/CustomPagination';
import { TableHead, TableRow, TableHeader, TableCell, TableBody, Table } from "@/components/ui/table";
import { DropdownMenuTrigger, DropdownMenuItem, DropdownMenuContent, DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuLabel, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import BoxApi from '@/common/BoxApi';
import { PageQueryInboxReqModel, SetInboxPasswordReqModel, ChangeInboxStateReqModel } from '@/common/model/request/InboxReqModel';
import { BoxRespModel, PageRespModel } from '@/common/model/BoxRespModel';
import { toast } from '@/components/ui/use-toast';
import { InboxInfoModel } from '@/common/model/InboxInfoModel';
import { EditInboxBasicInfoReqModel } from '@/common/model/request/InboxReqModel';
import { Share, CalendarCheck, EllipsisVertical, Search, Filter, X, ArrowUpDown, ArrowUp, ArrowDown, LockKeyhole, LockOpen, CheckCircle2, XCircle, Clock, AlertCircle } from 'lucide-react';
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import FilterSolidIcon from '@/components/FilterSolidIcon';
import DateTimePicker from '@/components/DateTimePicker';
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import CryptoJS from 'crypto-js'

export default function InboxList() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [collectionList, setCollectionList] = useState<CollectionListItem[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');
    const [sortField, setSortField] = useState<'ctime' | 'deadlineTime'>('ctime');
    const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
    const [state, setState] = useState<'draft' | 'active' | 'paused' | 'expired' | 'closed' | null>(null);
    const [hasPwd, setHasPwd] = useState<boolean | null>(null);
    const [selectedInbox, setSelectedInbox] = useState<CollectionListItem | null>(null);
    const [password, setPassword] = useState('');
    const [isPasswordEnabled, setIsPasswordEnabled] = useState(false);

    // 使用 useMemo 创建防抖函数
    const debouncedSearch = useMemo(
        () => debounce((value: string) => {
            setSearchTerm(value);
            setCurrentPage(1);
        }, 800),
        []
    );

    // 使用 useCallback 包装搜索处理函数
    const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value;
        setInputValue(newSearchTerm);
        debouncedSearch(newSearchTerm);
    }, [debouncedSearch]);

    const handleClear = useCallback(() => {
        setInputValue('');
        debouncedSearch('');
    }, [debouncedSearch]);

    useEffect(() => {
        if (!BoxRequest.getLoginFlag()) {
            navigate('/signin');
            return;
        }

        loadCollectionList(currentPage, pageSize, searchTerm, sortField, sortType, state, hasPwd);
    }, [currentPage, pageSize, searchTerm, sortField, sortType, state, hasPwd, navigate]);

    const loadCollectionList = async (pageNo: number, pageSize: number, search: string, sortField: 'ctime' | 'deadlineTime', sortType: 'asc' | 'desc', state: 'draft' | 'active' | 'paused' | 'expired' | 'closed' | null, hasPwd: boolean | null) => {
        setIsLoading(true);
        let reqModel = new PageQueryInboxReqModel();
        reqModel.pageNo = pageNo;
        reqModel.pageSize = pageSize;
        reqModel.name = search;
        reqModel.sortField = sortField;
        reqModel.sortType = sortType;
        reqModel.state = state;
        reqModel.hasPwd = hasPwd;
        try {
            const resp: BoxRespModel<PageRespModel<InboxInfoModel>> = await BoxRequest.post(BoxApi.pageQueryInbox, reqModel);
            if (!resp.data) {
                throw new Error('No data received');
            }
            let pageResp: PageRespModel<InboxInfoModel> = resp.data;
            setCurrentPage(pageResp.current);
            setTotalPages(pageResp.pages);
            setTotalCount(pageResp.total);
            if (pageResp.size === 0) {
                console.info(`Inbox list is empty, currentPage:${pageResp.current}, totalPages:${pageResp.pages}`);
                return;
            }
            setCollectionList(CollectionListItem.fromCollectionInfoModelList(pageResp.records));
        } catch (err) {
            console.warn(`Query inbox list failed: ${err}`);
            toast({
                variant: 'destructive',
                title: "Query Failed",
                description: `${err}`,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSort = (field: 'ctime' | 'deadlineTime') => {
        if (field === sortField) {
            setSortType(sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortType('desc');
        }
    };

    const renderSortIcon = (field: 'ctime' | 'deadlineTime') => {
        if (field !== sortField) {
            return <ArrowUpDown className="ml-2 h-4 w-4 inline" />;
        }
        return sortType === 'asc' ? <ArrowUp className="ml-2 h-4 w-4 inline" /> : <ArrowDown className="ml-2 h-4 w-4 inline" />;
    };

    const confirmSetDate = async (inbox: CollectionListItem, newDate: Date | undefined) => {
        // 设置收件夹到期时间
        if (inbox && newDate) {
            let reqModel = new EditInboxBasicInfoReqModel();
            reqModel.inboxId = inbox.inboxId;
            reqModel.deadlineTime = newDate.getTime();
            reqModel.name = inbox.name || '';
            reqModel.description = inbox.description || '';
            try {
                const resp: BoxRespModel<boolean> = await BoxRequest.post(BoxApi.editInboxBasicInfo, reqModel);
                if (resp.success) {
                    toast({
                        title: "Deadline time updated",
                        description: "The deadline time has been successfully updated.",
                    });
                    loadCollectionList(currentPage, pageSize, searchTerm, sortField, sortType, state, hasPwd);
                } else {
                    throw new Error(resp.displayMsg || 'Failed to update deadline time');
                }
            } catch (err) {
                toast({
                    variant: 'destructive',
                    title: "Update Failed",
                    description: `${err}`,
                });
            }
        }
    };

    const handleDelete = (inbox: CollectionListItem) => {
        if (window.confirm("Are you sure you want to delete this inbox?")) {
            confirmDelete(inbox);
        }
    };

    const confirmDelete = async (inbox: CollectionListItem) => {
        try {
            const resp: BoxRespModel<boolean> = await BoxRequest.post(BoxApi.deleteInbox, {inboxId: inbox.inboxId});
            if (resp.success) {
                toast({
                    title: "Inbox deleted",
                    description: "The inbox has been successfully deleted.",
                });
                loadCollectionList(currentPage, pageSize, searchTerm, sortField, sortType, state, hasPwd);
            } else {
                throw new Error(resp.displayMsg || 'Failed to delete inbox');
            }
        } catch (err) {
            toast({
                variant: 'destructive',
                title: "Delete Failed",
                description: `${err}`,
            });
        }
    };

    const clearFilters = () => {
        setState(null);
        setHasPwd(null);
    };

    const tableHeaderClass = "bg-white hover:bg-gray-100";
    const tableColumnClass = "px-6 py-4 select-none";
    const tableRowClass = "hover:bg-gray-50 border-b border-gray-200";

    const isFiltered = state !== null || hasPwd !== null;

    const handleSetPassword = async () => {
        if (!selectedInbox) return;
        
        try {
            let reqModel = new SetInboxPasswordReqModel();
            reqModel.inboxId = selectedInbox.inboxId;
            reqModel.hasPwd = isPasswordEnabled;
            reqModel.pwd = password;
            reqModel.pwdHash = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
            const resp: BoxRespModel<boolean> = await BoxRequest.post(BoxApi.setInboxPassword, reqModel);
            if (resp.success) {
                toast({
                    title: "Password Updated",
                    description: "The collection password has been successfully updated.",
                });
                setPassword('');
                loadCollectionList(currentPage, pageSize, searchTerm, sortField, sortType, state, hasPwd);
            } else {
                throw new Error(resp.displayMsg || 'Failed to update password');
            }
        } catch (err) {
            toast({
                variant: 'destructive',
                title: "Update Failed",
                description: `${err}`,
            });
        }
    };

    const handleChangeState = async (inbox: CollectionListItem, newState: 'active' | 'paused') => {
        try {
            let reqModel = new ChangeInboxStateReqModel();
            reqModel.inboxId = inbox.inboxId;
            reqModel.newState = newState;
            
            const resp: BoxRespModel<boolean> = await BoxRequest.post(BoxApi.changeInboxState, reqModel);
            if (resp.success) {
                toast({
                    title: "Status Updated",
                    description: `Collection has been ${newState === 'active' ? 'started' : 'paused'}.`,
                });
                // 重新加载列表以更新状态
                loadCollectionList(currentPage, pageSize, searchTerm, sortField, sortType, state, hasPwd);
            } else {
                throw new Error(resp.displayMsg || `Failed to ${newState === 'active' ? 'start' : 'pause'} collection`);
            }
        } catch (err) {
            toast({
                variant: 'destructive',
                title: "Update Failed",
                description: `${err}`,
            });
        }
    };

    return (
        <div className="flex flex-col h-full">
            <div className="p-4 border-b border-gray-200">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-semibold text-gray-800">File Collections</h1>
                    <Button onClick={() => navigate('/inbox/create')}>Create Collection</Button>
                </div>
                
                <div className="flex items-center space-x-2 mb-2">
                    <div className="relative flex-grow">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <Input 
                            type="text" 
                            placeholder="Search collections..." 
                            className="pl-10 pr-10" 
                            value={inputValue}
                            onChange={handleSearch}
                        />
                        {inputValue && (
                            <button
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                                onClick={handleClear}
                            >
                                <X className="h-4 w-4" />
                            </button>
                        )}
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline">
                                {isFiltered ? (
                                    <FilterSolidIcon className="mr-2 h-4 w-4" />
                                ) : (
                                    <Filter className="mr-2 h-4 w-4" />
                                )}
                                Filter
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuItem onSelect={clearFilters}>
                                <X className="mr-2 h-4 w-4" />
                                Clear Filters
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuLabel>State</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuCheckboxItem
                                checked={state === 'draft'}
                                onCheckedChange={() => setState(state === 'draft' ? null : 'draft')}
                            >
                                Draft
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                                checked={state === 'paused'}
                                onCheckedChange={() => setState(state === 'paused' ? null : 'paused')}
                            >
                                Paused
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                                checked={state === 'active'}
                                onCheckedChange={() => setState(state === 'active' ? null : 'active')}
                            >
                                Active
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                                checked={state === 'expired'}
                                onCheckedChange={() => setState(state === 'expired' ? null : 'expired')}
                            >
                                Expired
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuLabel>Password</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuCheckboxItem
                                checked={hasPwd === true}
                                onCheckedChange={() => setHasPwd(hasPwd === true ? null : true)}
                            >
                                Has Password
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem
                                checked={hasPwd === false}
                                onCheckedChange={() => setHasPwd(hasPwd === false ? null : false)}
                            >
                                No Password
                            </DropdownMenuCheckboxItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>

            <div className="flex-1 overflow-hidden">
                {isLoading ? (
                    <div className="flex items-center justify-center h-full">
                        <p>Loading...</p>
                    </div>
                ) : (
                    <div className="relative h-full">
                        <div className="absolute top-0 left-0 right-0 z-10">
                            <div className="min-w-[1040px]">
                                <Table>
                                    <TableHeader>
                                        <TableRow className="border-b border-gray-300">
                                            <TableHead className={cn(tableHeaderClass, tableColumnClass, "w-[300px]")}>
                                                Collection Name
                                            </TableHead>
                                            <TableHead 
                                                className={cn(tableHeaderClass, tableColumnClass, "w-[200px] cursor-pointer")}
                                                onClick={() => handleSort('ctime')}
                                            >
                                                Created At
                                                {renderSortIcon('ctime')}
                                            </TableHead>
                                            <TableHead 
                                                className={cn(tableHeaderClass, tableColumnClass, "w-[200px] cursor-pointer")}
                                                onClick={() => handleSort('deadlineTime')}
                                            >
                                                Deadline
                                                {renderSortIcon('deadlineTime')}
                                            </TableHead>
                                            <TableHead className={cn(tableHeaderClass, tableColumnClass, "w-[120px]")}>Status</TableHead>
                                            <TableHead className={cn(tableHeaderClass, tableColumnClass, "w-[100px] text-center")}>Files</TableHead>
                                            <TableHead className={cn(tableHeaderClass, tableColumnClass, "w-[120px] text-right")}>Actions</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                </Table>
                            </div>
                        </div>
                        <div className="absolute inset-0 overflow-auto pt-12">
                            <div className="min-w-[1040px]">
                                <Table>
                                    <TableBody>
                                        {collectionList.map((row: CollectionListItem, index) => (
                                            <TableRow key={index} className={tableRowClass}>
                                                <TableCell className={cn(tableColumnClass, "w-[300px] font-medium cursor-pointer")} onClick={()=>navigate(`/inbox/${row.inboxId}/manage/files`)}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell className={cn(tableColumnClass, "w-[200px]")}>{row.ctime ? new Date(row.ctime).toLocaleString() : 'N/A'}</TableCell>
                                                <TableCell className={cn(tableColumnClass, "w-[200px]")}>{row.deadlineTime ? new Date(row.deadlineTime).toLocaleString() : 'N/A'}</TableCell>
                                                <TableCell className={cn(tableColumnClass, "w-[120px]")}>
                                                    {row.state === 'active' && (
                                                        <div className="flex items-center text-green-600">
                                                            <CheckCircle2 className="mr-2 h-4 w-4" />
                                                            Active
                                                        </div>
                                                    )}
                                                    {row.state === 'draft' && (
                                                        <div className="flex items-center text-yellow-600">
                                                            <Clock className="mr-2 h-4 w-4" />
                                                            Draft
                                                        </div>
                                                    )}
                                                    {row.state === 'paused' && (
                                                        <div className="flex items-center text-red-600">
                                                            <XCircle className="mr-2 h-4 w-4" />
                                                            Paused
                                                        </div>
                                                    )}
                                                    {row.state === 'expired' && (
                                                        <div className="flex items-center text-gray-600">
                                                            <AlertCircle className="mr-2 h-4 w-4" />
                                                            Expired
                                                        </div>
                                                    )}
                                                    {row.state === 'closed' && (
                                                        <div className="flex items-center text-gray-600">
                                                            <XCircle className="mr-2 h-4 w-4" />
                                                            Closed
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell className={cn(tableColumnClass, "w-[100px] text-center")}>
                                                    <div className="flex justify-center">
                                                        <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-700">
                                                            {row.fileCount} {row.fileCount === 1 ? 'file' : 'files'}
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={cn(tableColumnClass, "w-[120px] text-right")}>
                                                    <div className="flex justify-end items-center space-x-2">
                                                        {row.shareable ? (
                                                            <Link to={`/inbox/share?inboxId=${row.inboxId}`} target="_blank">
                                                                <Share 
                                                                    className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
                                                                />
                                                            </Link>
                                                        ) : (
                                                            <Share 
                                                                className="h-5 w-5 text-gray-400 cursor-not-allowed"
                                                            />
                                                        )}
                                                        <Popover>
                                                            <PopoverTrigger asChild>
                                                                <CalendarCheck 
                                                                    className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" 
                                                                />
                                                            </PopoverTrigger>
                                                            <PopoverContent className="w-auto p-0" align="end">
                                                                <DateTimePicker
                                                                    onConfirm={(newDate) => confirmSetDate(row, newDate)}
                                                                />
                                                            </PopoverContent>
                                                        </Popover>
                                                        <Popover>
                                                            <PopoverTrigger asChild>
                                                                {row.hasPwd ? (
                                                                    <LockKeyhole 
                                                                        className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" 
                                                                    />
                                                                ) : (
                                                                    <LockOpen 
                                                                        className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer" 
                                                                    />
                                                                )}
                                                            </PopoverTrigger>
                                                            <PopoverContent className="w-80" align="end">
                                                                <div className="grid gap-4">
                                                                    <div className="space-y-2">
                                                                        <h4 className="font-medium leading-none">Password Protection</h4>
                                                                        <p className="text-sm text-muted-foreground">
                                                                            {row.hasPwd ? 'This collection is password protected' : 'Set password protection for this collection'}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex items-center space-x-2">
                                                                        <Switch
                                                                            checked={row.hasPwd || isPasswordEnabled}
                                                                            onCheckedChange={(checked) => {
                                                                                setIsPasswordEnabled(checked);
                                                                                if (!checked) setPassword('');
                                                                            }}
                                                                        />
                                                                        <Label>Enable Password</Label>
                                                                    </div>
                                                                    {(row.hasPwd || isPasswordEnabled) && (
                                                                        <div className="space-y-2">
                                                                            <Label htmlFor="password">Password</Label>
                                                                            <Input
                                                                                id="password"
                                                                                type="password"
                                                                                value={password}
                                                                                onChange={(e) => setPassword(e.target.value)}
                                                                                placeholder={row.hasPwd ? "Enter new password" : "Enter password"}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    <div className="flex justify-end space-x-2">
                                                                        <Button 
                                                                            onClick={() => {
                                                                                setSelectedInbox(row);
                                                                                handleSetPassword();
                                                                            }}
                                                                            disabled={isPasswordEnabled && !password}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </PopoverContent>
                                                        </Popover>
                                                        <DropdownMenu>
                                                            <DropdownMenuTrigger asChild>
                                                                <EllipsisVertical className="h-5 w-5 text-gray-400 cursor-pointer" />
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent align="end">
                                                                <DropdownMenuItem onClick={() => handleDelete(row)}>Delete</DropdownMenuItem>
                                                                {row.state === 'active' && (
                                                                    <DropdownMenuItem onClick={() => handleChangeState(row, 'paused')}>Pause</DropdownMenuItem>
                                                                )}
                                                                {row.state === 'paused' && (
                                                                    <DropdownMenuItem onClick={() => handleChangeState(row, 'active')}>Start</DropdownMenuItem>
                                                                )}
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="p-4 border-t border-gray-200">
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalCount={totalCount}
                    initPageSize={pageSize}
                    onPageChange={(newPage: number, newPageSize: number) => { setCurrentPage(newPage); setPageSize(newPageSize) }}
                />
            </div>
        </div>
    );
}

class CollectionListItem {
    inboxId: string = '';
    name: string | null = '';
    description: string | null = '';
    ctime: number | null = 0;
    deadlineTime: number | null = 0;
    shareable: boolean = false;
    fileCount: number = 0;
    hasPwd: boolean = false;
    state: 'draft' | 'expired' | 'paused' | 'active' | 'closed' = 'draft';

    static fromInboxInfoModel(inboxInfo: InboxInfoModel): CollectionListItem {
        let item = new CollectionListItem();
        item.inboxId = inboxInfo.inboxId;
        item.name = inboxInfo.name;
        item.description = inboxInfo.description;
        item.ctime = inboxInfo.ctime;
        item.deadlineTime = inboxInfo.deadlineTime;
        item.shareable = (inboxInfo.state === 'active');
        item.fileCount = inboxInfo.fileCount || 0;
        item.hasPwd = inboxInfo.hasPwd;
        item.state = inboxInfo.state;
        return item;
    }

    static fromCollectionInfoModelList(collectionInfoList: InboxInfoModel[]): CollectionListItem[] {
        return collectionInfoList.map((collectionInfo) => CollectionListItem.fromInboxInfoModel(collectionInfo));
    }
}
