import { useState, useEffect } from 'react';
import { BoxPageHeader } from '@/components/BoxHeaderFooter';
import { Button } from "@/components/ui/button";
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';

const navItems = [
    { id: 'dashboard', label: 'Dashboard', href: '/inboxes/dashboard' },
    { id: 'inboxes', label: 'Inboxes', href: '/inboxes/list' },
];

export default function InboxLayout() {
    const [contentHeight, setContentHeight] = useState('calc(100vh - 64px)');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const updateContentHeight = () => {
            const header = document.querySelector('header');
            if (header) {
                const headerHeight = header.offsetHeight;
                setContentHeight(`calc(100vh - ${headerHeight}px)`);
            }
        };

        updateContentHeight();
        window.addEventListener('resize', updateContentHeight);

        return () => window.removeEventListener('resize', updateContentHeight);
    }, []);

    const isActive = (href: string) => {
        if (href === '/inboxes/list') {
            return location.pathname === '/inboxes' || location.pathname.startsWith('/inboxes/list');
        }
        return location.pathname.startsWith(href);
    };

    // 添加重定向逻辑
    if (location.pathname === '/inboxes') {
        return <Navigate to="/inboxes/list" replace />;
    }

    return (
        <div className="flex flex-col h-screen">
            <BoxPageHeader 
                defaultBgClassName="bg-white" 
                scrollBgClassName="bg-white shadow-sm"
            />
            <div className="flex flex-1 overflow-hidden" style={{ height: contentHeight }}>
                <nav className="w-64 bg-gray-50 p-4 overflow-y-auto">
                    {navItems.map(item => (
                        <Button
                            key={item.id}
                            variant="ghost"
                            className={`w-full mb-2 justify-start text-left transition-colors duration-200 ${
                                isActive(item.href)
                                    ? 'bg-blue-50 text-blue-600 hover:bg-blue-100' 
                                    : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                            }`}
                            onClick={() => navigate(item.href)}
                            aria-label={`Navigate to ${item.label}`}
                        >
                            {item.label}
                        </Button>
                    ))}
                </nav>
                <main className="flex-1 bg-white overflow-auto">
                    <Outlet />
                </main>
            </div>
        </div>
    );
}