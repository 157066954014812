import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { ChevronLeft, ChevronDown, ChevronUp, Share, SquarePen, Link, File as FileIcon, Mail as MailIcon, Calendar as CalendarIcon, Users2 as UserIcon } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { InboxInfoModel } from "@/common/model/InboxInfoModel"
import DateTimePicker from '@/components/DateTimePicker'
import { useState, useEffect } from "react"
import { formatDateTime } from "@/common/util/DateUtil"

interface InboxHeaderProps {
    inboxInfo: InboxInfoModel
    inboxId: string
    onShareLink: () => void
    onUpdateDeadline: (date: Date) => void
    onUpdateNotify: (enabled: boolean, email: string, notifyOnDeadline: boolean) => void
}

export function InboxHeader({
    inboxInfo,
    inboxId,
    onShareLink,
    onUpdateDeadline,
    onUpdateNotify
}: InboxHeaderProps) {
    const navigate = useNavigate()
    const [isNotifyEnabled, setIsNotifyEnabled] = useState(!!inboxInfo.notifyEmail)
    const [notifyEmail, setNotifyEmail] = useState(inboxInfo.notifyEmail || '')
    const [notifyOnDeadline, setNotifyOnDeadline] = useState(inboxInfo.notifyOnDeadline || false)
    const [deadlineTimeStr, setDeadlineTimeStr] = useState('Closed')

    const isClosed = (deadlineTime: number) => {
        return deadlineTime < Date.now()
    }


    useEffect(() => {
        if (inboxInfo.deadlineTime) {
            const deadlineTime = new Date(inboxInfo.deadlineTime)
            const isClosed = deadlineTime < new Date()
            setDeadlineTimeStr(isClosed ? 'Closed' : formatDateTime(deadlineTime))
        }
    }, [inboxInfo.deadlineTime])

    const handleDateConfirm = (date: Date | undefined) => {
        if (date) {
            onUpdateDeadline(date)
        }
    }

    const [isCollapsed, setIsCollapsed] = useState(false)

    useEffect(() => {
        // 自动展示统计信息
        setIsCollapsed(false)

        // 1秒后开始收缩动画
        const timer = setTimeout(() => {
            setIsCollapsed(true)
        }, 2000)

        // 清理定时器
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <div className="flex justify-between items-center py-4">
                <div className="flex items-center space-x-4">
                    <Button variant="ghost" onClick={() => window.history.back()}>
                        <ChevronLeft className="w-6 h-6" />
                    </Button>
                    <h1 className="text-xl font-semibold">{inboxInfo.name}</h1>
                </div>
                <div className="flex space-x-2">
                    <Button variant="outline" onClick={() => setIsCollapsed(!isCollapsed)}>
                        {isCollapsed ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
                        {isCollapsed ? 'Show' : 'Hide'} Stats
                    </Button>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <span>
                                    <Button
                                        variant="outline"
                                        onClick={() => {
                                            if (!isClosed(inboxInfo.deadlineTime)) {
                                                navigate(`/inbox/share?inboxId=${inboxId}`, { state: { inboxInfo } });
                                            }
                                        }}
                                        disabled={isClosed(inboxInfo.deadlineTime)}
                                    >
                                        <Share className="w-5 h-5 mr-2" />
                                        Share
                                    </Button>
                                </span>
                            </TooltipTrigger>
                            <TooltipContent>
                                {isClosed(inboxInfo.deadlineTime)
                                    ? <p>This inbox has been closed and cannot be shared</p>
                                    : <p>Share this inbox</p>
                                }
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    <Button variant="outline">
                        <SquarePen className="w-5 h-5 mr-2" />
                        Edit
                    </Button>
                    <Button variant="outline" onClick={onShareLink}>
                        <Link className="w-5 h-5 mr-2" />
                        Copy link
                    </Button>
                </div>
            </div>
            <div className={`grid gap-6 md:grid-cols-2 lg:grid-cols-4 my-6 overflow-hidden transition-all duration-500 ease-in-out  ${isCollapsed ? 'max-h-0' : 'max-h-[500px]'}`}>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">Files</CardTitle>
                        <FileIcon />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{inboxInfo.fileCount}</div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">Submit users</CardTitle>
                        <UserIcon />
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{0}</div>
                    </CardContent>
                </Card>
                <Popover>
                    <PopoverTrigger asChild>
                        <Card className="cursor-pointer hover:bg-gray-50 transition-colors">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">Notify email</CardTitle>
                                <MailIcon />
                            </CardHeader>
                            <CardContent>
                                <div className="text-lg font-bold truncate" title={inboxInfo.notifyEmail || 'No email set'}>
                                    {inboxInfo.notifyEmail || 'No email set'}
                                </div>
                            </CardContent>
                        </Card>
                    </PopoverTrigger>
                    <PopoverContent className="w-80">
                        <div className="grid gap-4">
                            <div className="space-y-2">
                                <h4 className="font-medium leading-none">Email Notifications</h4>
                                <p className="text-sm text-muted-foreground">
                                    Configure email notifications for this collection
                                </p>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Switch
                                    checked={isNotifyEnabled}
                                    onCheckedChange={(checked) => {
                                        setIsNotifyEnabled(checked)
                                        if (!checked) setNotifyEmail('')
                                    }}
                                />
                                <Label>Enable Notifications</Label>
                            </div>
                            {isNotifyEnabled && (
                                <>
                                    <div className="space-y-2">
                                        <Label htmlFor="email">Notification Email</Label>
                                        <Input
                                            id="email"
                                            type="email"
                                            value={notifyEmail}
                                            onChange={(e) => setNotifyEmail(e.target.value)}
                                            placeholder="Enter email address"
                                        />
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            checked={notifyOnDeadline}
                                            onCheckedChange={setNotifyOnDeadline}
                                        />
                                        <Label>Notify on deadline</Label>
                                    </div>
                                </>
                            )}
                            <Button
                                onClick={() => onUpdateNotify(isNotifyEnabled, notifyEmail, notifyOnDeadline)}
                                disabled={isNotifyEnabled && !notifyEmail}
                            >
                                Save
                            </Button>
                        </div>
                    </PopoverContent>
                </Popover>
                <Popover>
                    <PopoverTrigger asChild>
                        <Card className="cursor-pointer hover:bg-gray-50 transition-colors">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">Deadline time</CardTitle>
                                <CalendarIcon />
                            </CardHeader>
                            <CardContent>
                                <div className="text-lg font-bold truncate" title={deadlineTimeStr}>
                                    {deadlineTimeStr}
                                </div>
                            </CardContent>
                        </Card>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <DateTimePicker onConfirm={handleDateConfirm} />
                    </PopoverContent>
                </Popover>
            </div>
        </>
    )
} 