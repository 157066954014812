import { useState, useEffect } from 'react';
import { useToast } from '@/components/ui/use-toast';
import BoxApi from '@/common/BoxApi';
import BoxRequest from '@/common/util/BoxRequest';
import { BoxRespModel } from '@/common/model/BoxRespModel';
import { useNavigate, useParams } from 'react-router-dom';
import { InboxInfoModel } from '@/common/model/InboxInfoModel';
import { BoxPageHeader, BoxPageFooter } from '@/components/BoxHeaderFooter';
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { AlignLeft, Calendar, Lock, Upload, AlertCircle, Home, Clock, PlusCircle } from 'lucide-react'
import CryptoJS from 'crypto-js';
import { Progress } from "@/components/ui/progress"
import { format, isValid } from 'date-fns';
import { Input } from "@/components/ui/input" // 添加这行

export default function Component() {

    const { toast } = useToast();
    const navigate = useNavigate();
    const { inboxId } = useParams();

    // 删除未使用的 defaultInboxInfo
    const [inboxInfo, setInboxInfo] = useState<InboxInfoModel>(new InboxInfoModel());
    const [description, setDescription] = useState<string>('');
    const [cutoffTime, setCutoffTime] = useState<string>('');
    const [inputPassword, setInputPassword] = useState<string>('');
    const [uploadProgressNumber, setUploadProgressNumber] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [dragActive, setDragActive] = useState(false);

    const fetchInboxInfo = async () => {
        if (!inboxId)
            return;
        setIsLoading(true);
        try {
            const resp: BoxRespModel<InboxInfoModel> = await BoxRequest.post(BoxApi.getInboxInfo, { inboxId: inboxId });
            if (resp.success && null != resp.data) {
                setInboxInfo(resp.data);
                console.log("Received deadlineTime:", resp.data.deadlineTime); // 添加这行日志
                let descriptionStr = resp.data.description;
                try {
                    descriptionStr = JSON.parse(descriptionStr)?.name ?? descriptionStr;
                } catch (err) {
                    console.error(`Parse description fail: ${err}`);
                }
                setDescription(descriptionStr);
                let deadlineTime = new Date(resp.data.deadlineTime);
                const expireTimeStr = `${deadlineTime.getFullYear()}-${String(deadlineTime.getMonth() + 1).padStart(2, '0')}-${String(deadlineTime.getDate()).padStart(2, '0')}T${String(deadlineTime.getHours()).padStart(2, '0')}:${String(deadlineTime.getMinutes()).padStart(2, '0')}`;
                setCutoffTime(expireTimeStr);

            } else {
                throw new Error(resp.displayMsg || 'Get inbox info fail.');
            }
        } catch (err) {
            toast({
                title: "Get inbox info fail.",
                description: `${err}`,
            });
            setTimeout(() => {
                navigate('/404');
            }, 2000);
        } finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {
        fetchInboxInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxId]);

    const formatDateTime = (dateString: string | number) => {
        let date: Date;
        if (typeof dateString === 'number') {
            date = new Date(dateString);
        } else {
            // 尝试解析字符串日期
            date = new Date(dateString);
            if (isNaN(date.getTime())) {
                // 如果解析失败，尝试将字符串作为时间戳解析
                const timestamp = parseInt(dateString);
                if (!isNaN(timestamp)) {
                    date = new Date(timestamp);
                }
            }
        }

        if (isValid(date)) {
            return format(date, "yyyy-MM-dd HH:mm");
        }
        return "Invalid Date";
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFiles(Array.from(event.target.files));
        }
    };

    const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFiles(Array.from(event.dataTransfer.files));
        }
    };

    const submitFiles = () => {
        console.info('submitFiles clicked!');
        if (selectedFiles.length === 0) {
            toast({
                title: 'No file selected!',
                description: 'Please select a file to submit.',
            });
            return;
        }

        if (inboxInfo.hasPwd && !inputPassword) {
            toast({
                title: 'Password required!',
                description: 'This inbox is password-protected. Please enter the password.',
                variant: "destructive",
            });
            return;
        }

        BoxRequest.postWithFiles(BoxApi.uploadFile, {
            inboxId: inboxId,
            fileName: selectedFiles[0].name,
            fileSize: selectedFiles[0].size,
            file: selectedFiles[0],
            pwdHash: inboxInfo.hasPwd ? CryptoJS.SHA256(inputPassword).toString(CryptoJS.enc.Hex) : '',
        }, (progress: number) => {
            console.info('upload file progress:', progress);
            setUploadProgressNumber(progress);
        }).then((resp) => {
            if (resp.success) {
                toast({
                    title: 'Submit file SUCCESS!',
                    description: 'The file has been submitted successfully.',
                });
                setTimeout(() => {
                    navigate('/inbox/uploadComplete');
                }, 2000);
            } else {
                toast({
                    variant: "destructive",
                    title: 'Submit FAIL!',
                    description: `${resp.displayMsg}`,
                });
                setUploadProgressNumber(0);
            }
        }).catch((err) => {
            toast({
                variant: "destructive",
                title: 'Submit FAIL!',
                description: `${err}`,
            });
            setUploadProgressNumber(0);
        });

    }

    const displayValue = (value: number | string | undefined) => {
        if (isLoading || value === undefined || value === null || value === '') {
            return "-";
        }
        return typeof value === 'number' ? value.toString() : value;
    };

    const isClosed = (deadlineTime: number) => {
        return deadlineTime < Date.now();
    };

    const getSupportedFileTypes = () => {
        if (inboxInfo.limitFileTypeFlag && inboxInfo.allowedFileTypes) {
            return inboxInfo.allowedFileTypes.split(',').join(', ').toUpperCase();
        }
        return null; // 如果有限制文件类型，返回 null
    };

    const getMaxFileSize = () => {
        if (inboxInfo.maxFileSize > 0) {
            return (inboxInfo.maxFileSize / (1024 * 1024)).toFixed(0); // 转换为 MB
        }
        return null; // 如果没有设置最大文件大小，返回 null
    };

    const getFileUploadInfoText = () => {
        const maxSize = getMaxFileSize();
        const supportedTypes = getSupportedFileTypes();
        let infoText = '';

        if (maxSize) {
            infoText += `Max file size: ${maxSize}MB. `;
        }

        if (supportedTypes) {
            infoText += `Supported formats: ${supportedTypes}.`;
        } else {
            infoText += 'All file types are supported.';
        }

        return infoText.trim();
    };

    const renderClosedContent = () => {
        return (
            <Card className="w-full max-w-3xl mx-auto">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-center">Inbox Expired</CardTitle>
                    <CardDescription className="text-center">
                        The inbox <span className="font-mono text-primary">{inboxId}</span> has expired.
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                    <div className="flex items-center justify-center text-amber-500">
                        <AlertCircle className="w-12 h-12" />
                    </div>
                    <p className="text-center text-muted-foreground">
                        This inbox is no longer accepting file submissions.
                        The owner may have closed it or it may have reached its expiration date.
                    </p>
                    <div className="space-y-2">
                        <div className="flex items-center gap-2 text-muted-foreground">
                            <AlignLeft className="w-4 h-4" />
                            <span className="font-semibold">Description:</span>
                        </div>
                        <p className="text-sm text-muted-foreground pl-6">{displayValue(description)}</p>
                    </div>
                    <div className="space-y-2">
                        <div className="flex items-center gap-2 text-muted-foreground">
                            <Calendar className="w-4 h-4" />
                            <span className="font-semibold">Deadline Date:</span>
                        </div>
                        <p className="text-sm text-muted-foreground pl-6">
                            {formatDateTime(inboxInfo.deadlineTime)}
                        </p>
                    </div>
                    {inboxInfo.fileCount > 0 && (
                        <div className="space-y-2">
                            <div className="flex items-center gap-2 text-muted-foreground">
                                <Clock className="w-4 h-4" />
                                <span className="font-semibold">Submitted Files:</span>
                            </div>
                            <p className="text-sm text-muted-foreground pl-6">{inboxInfo.fileCount} file(s) submitted</p>
                        </div>
                    )}
                </CardContent>
                <CardFooter className="flex flex-col space-y-2">
                    <Button className="w-full" variant="outline" onClick={() => navigate('/')}>
                        <Home className="mr-2 h-4 w-4" />
                        Return to Homepage
                    </Button>
                    <Button className="w-full" onClick={() => navigate('/inbox/create')}>
                        <PlusCircle className="mr-2 h-4 w-4" />
                        Create New Inbox
                    </Button>
                </CardFooter>
            </Card>
        );
    }

    const renderContent = (status: 'draft' | 'paused' | 'active' | 'closed' | 'expired') => {
        if (isLoading) {
            return (
                <div className="flex justify-center items-center min-h-[300px]">
                    <p className="text-lg font-medium text-gray-600">Loading...</p>
                </div>
            );
        }

        if (status === 'closed' || isClosed(inboxInfo.deadlineTime)) {
            return renderClosedContent();
        }

        switch (status) {
            case 'active':
                return (
                    <Card className="max-w-3xl mx-auto">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">{displayValue(inboxInfo.name)}</CardTitle>
                            <CardDescription>Upload your files here</CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-6">
                            <div className="space-y-2">
                                <Label htmlFor="inboxDescription" className="flex items-center gap-2">
                                    <AlignLeft className="w-4 h-4 text-blue-500" />
                                    Description
                                </Label>
                                <Textarea 
                                    id="inboxDescription"
                                    value={displayValue(description)}
                                    disabled={true}
                                    placeholder='Description of the file collection task'
                                />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="inboxDeadline" className="flex items-center gap-2">
                                    <Calendar className="w-4 h-4 text-green-500" />
                                    Deadline
                                </Label>
                                <Input 
                                    id="inboxDeadline"
                                    type="text"
                                    disabled
                                    value={displayValue(cutoffTime ? formatDateTime(cutoffTime) : '-')}
                                />
                            </div>
                            {inboxInfo.hasPwd && (
                                <div className="space-y-2">
                                    <Label htmlFor="inboxPassword" className="flex items-center gap-2">
                                        <Lock className="w-4 h-4 text-red-500" />
                                        Inbox Password
                                    </Label>
                                    <p className="text-sm text-amber-600 mb-2">
                                        This inbox is password-protected. Please enter the password to submit files.
                                    </p>
                                    <Input 
                                        id="inboxPassword"
                                        type="password"
                                        placeholder="Enter Inbox Password"
                                        value={inputPassword}
                                        onChange={(e) => setInputPassword(e.target.value)}
                                    />
                                </div>
                            )}
                            <div
                                className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors ${dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                            >
                                <Upload className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                                <p className="text-sm text-gray-600 mb-2">Drag and drop your files here, or click to select files</p>
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="hidden"
                                    onChange={handleFileSelect}
                                    multiple
                                />
                                <Button 
                                    variant="outline" 
                                    className="mt-2"
                                    onClick={() => document.getElementById('file-upload')?.click()}
                                >
                                    Choose Files
                                </Button>
                                {selectedFiles.length > 0 && (
                                    <div className="mt-4 text-left">
                                        <p className="font-semibold mb-2">Selected files:</p>
                                        <ul className="list-disc pl-5">
                                            {selectedFiles.map((file, index) => (
                                                <li key={index} className="text-sm text-gray-600">
                                                    {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {uploadProgressNumber > 0 && (
                                <div className="w-full">
                                    <Progress value={uploadProgressNumber} className="w-full" />
                                    <p className="text-sm text-gray-600 mt-1">Uploading: {uploadProgressNumber}%</p>
                                </div>
                            )}
                        </CardContent>
                        <CardFooter className="flex flex-col items-stretch gap-4">
                            <Button 
                                className="w-full"
                                onClick={submitFiles}
                                disabled={selectedFiles.length === 0 || (inboxInfo.hasPwd && !inputPassword)}
                            >
                                Submit Files
                                <Upload className="w-4 h-4 ml-2" />
                            </Button>
                            <p className="text-xs text-gray-500 text-center">
                                {getFileUploadInfoText()}
                            </p>
                        </CardFooter>
                    </Card>
                );
            case 'draft':
                return (
                    <Card className="max-w-3xl mx-auto">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">{displayValue(inboxInfo.name)}</CardTitle>
                            <CardDescription className="text-yellow-500">The Inbox is in DRAFT mode! Please wait for the owner to publish it.</CardDescription>
                        </CardHeader>
                    </Card>
                );
            case 'expired':
                return (
                    <Card className="max-w-3xl mx-auto">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">{displayValue(inboxInfo.name)}</CardTitle>
                            <CardDescription className="text-red-500">The Inbox is EXPIRED! Cannot submit any file.</CardDescription>
                        </CardHeader>
                    </Card>
                );
            default:
                return (
                    <Card className="max-w-3xl mx-auto">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">{displayValue(inboxInfo.name)}</CardTitle>
                            <CardDescription className="text-orange-500">The Inbox is in an unknown status! Please contact the owner.</CardDescription>
                        </CardHeader>
                    </Card>
                );
        }
    }

    return (
        <div className="flex flex-col min-h-screen">
            <BoxPageHeader />
            <main className="flex-grow flex flex-col bg-gray-100">
                <div className="flex-grow flex items-center justify-center w-full max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
                    <section className="w-full py-10">
                        {renderContent(inboxInfo.state)}
                    </section>
                </div>
            </main>
            <BoxPageFooter />
        </div>
    )
}
